const PATH_PREFIX = process.env.REACT_APP_PATH_PREFIX;

export const Routing = {
  Login: `${PATH_PREFIX}/login`,
  ForgotPassword: `${PATH_PREFIX}/forgot-password`,

  Dashboard: `${PATH_PREFIX}/dashboard`,

  ServiceMenu: `${PATH_PREFIX}/service-menu`,
  Services: `${PATH_PREFIX}/service-menu/services`,
  Companies: `${PATH_PREFIX}/service-menu/companies`,
  Models: `${PATH_PREFIX}/service-menu/models`,
  Issues: `${PATH_PREFIX}/service-menu/issues`,
  BillingMethod: `${PATH_PREFIX}/service-menu/billing-method`,
  
  Providers: `${PATH_PREFIX}/providers`,
  ProviderDetails: `${PATH_PREFIX}/providers/:id?`,
  
  Customers: `${PATH_PREFIX}/customers`,
  
  Bookings: `${PATH_PREFIX}/bookings`,
  BookingHistory: `${PATH_PREFIX}/bookings/:id?`,
  CustomerRequestMap: `${PATH_PREFIX}/bookings/map/:userId/:requestId`,

  Commission: `${PATH_PREFIX}/commission`,
  
  Notification: `${PATH_PREFIX}/notification`,
  
  Payout: `${PATH_PREFIX}/payout`,  
  PayoutById: `${PATH_PREFIX}/payout/:id/Info`,

  ErrorPage: `${PATH_PREFIX}/error`,
  NotFound: `${PATH_PREFIX}/not-found`,
};
