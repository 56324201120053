import { CircularProgress } from '@mui/material';
import './loader.css';
import React from 'react';

const Loader: React.FC = () => {
    return (
        // <div id="loader" className="loader-wrapper" style={{ display: 'none', visibility: 'hidden' }}>
        //     <CircularProgress color="primary" thickness={3} size={44} />
        // </div>

        <div className="center-body">
            <div className="loader-shape-3" />
        </div>
    );
};

export default Loader;
