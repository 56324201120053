export enum Modules {
  Dashboard = "Dashboard",
  /** ServiceMenu */
  ServiceMenu = "Service Menu",
  Services = "Services",
  Companies = "Companies",
  Models = "Models",
  Issues = "Issues",
  BillingMethod = "Billing Method",
  Bookings = "Bookings",
  /** Providers */
  Providers = "Providers",
  /** Customers */
  Customers = "Customers",
  Notification = "Notification",
  Commission = "Commission",
  Payout = "Payout"
}
