import React, { lazy } from "react";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import { Routing } from "./routing";
import ProtectedRoute from "./ProtectedRoute";


const PublicLayout = lazy(() => import("../layout/PublicLayout"));
const MainLayout = lazy(() => import("../layout/MainLayout"));

const Login = lazy(() => import("../pages/Account/Login"));
const ForgotPassword = lazy(() => import("../pages/Account/ForgotPassword"));

const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const Services = lazy(() => import("../pages/ServiceMenu/Services/Services"));
const Companies = lazy(() => import("../pages/ServiceMenu/Companies/Companies"));
const Models = lazy(() => import("../pages/ServiceMenu/Models/Models"));
const Issues = lazy(() => import("../pages/ServiceMenu/Issues/Issues"));
const BillingMethod = lazy(() => import("../pages/ServiceMenu/Billing Method/BillingMethod"));
const Bookings = lazy(() => import("../pages/Bookings/Bookings"));
const BookingHistory = lazy(() => import("../pages/Bookings/BookingHistory"));
const CustomerRequestMap = lazy(() => import("../pages/Bookings/CustomerRequestMap"));
const Providers = lazy(() => import("../pages/Providers/Providers"));
const Customers = lazy(() => import("../pages/Customers/Customers"));
const ProviderDetails = lazy(() => import("../pages/Providers/ProviderDetails"));

const Commission = lazy(() => import("../pages/Commission/Commission"));

const Notification = lazy(() => import("../pages/Notification/Notification"));
const Payout = lazy(() => import("../pages/Payout/Payout"));
const NotFound = lazy(() => import("../pages/Account/NotFound"));
const PayoutById = lazy(() => import("../pages/Payout/PayoutById"));


const privateRoute = (Element: any, props?: any) => {
  return <ProtectedRoute element={props ? <Element {...props} /> : <Element />} />;
};

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={Routing.Login} />,
  },
  {
    path: Routing.Login,
    element: (<PublicLayout><Login /></PublicLayout>),
  },
  {
    path: Routing.ForgotPassword,
    element: (<PublicLayout><ForgotPassword /></PublicLayout>),
  },
  {
    path: Routing.Dashboard,
    element: <MainLayout>{privateRoute(Dashboard)}</MainLayout>,
  },
  {
    path: Routing.Services,
    element: <MainLayout>{privateRoute(Services)}</MainLayout>,
  },
  {
    path: Routing.Companies,
    element: <MainLayout>{privateRoute(Companies)}</MainLayout>,
  },
  {
    path: Routing.Models,
    element: <MainLayout>{privateRoute(Models)}</MainLayout>,
  },
  {
    path: Routing.Issues,
    element: <MainLayout>{privateRoute(Issues)}</MainLayout>,
  },
  {
    path: Routing.BillingMethod,
    element: <MainLayout>{privateRoute(BillingMethod)}</MainLayout>,
  },
  {
    path: Routing.Bookings,
    element: <MainLayout>{privateRoute(Bookings)}</MainLayout>,
  },
  {
    path: Routing.BookingHistory,
    element: <MainLayout>{privateRoute(BookingHistory)}</MainLayout>,
  },
  {
    path: Routing.CustomerRequestMap,
    element: <MainLayout>{privateRoute(CustomerRequestMap)}</MainLayout>,
  },
  {
    path: Routing.Providers,
    element: <MainLayout>{privateRoute(Providers)}</MainLayout>,
  },
  {
    path: Routing.ProviderDetails,
    element: <MainLayout>{privateRoute(ProviderDetails)}</MainLayout>,
  },
  {
    path: Routing.Customers,
    element: <MainLayout>{privateRoute(Customers)}</MainLayout>,
  },
  {
    path: Routing.Notification,
    element: <MainLayout>{privateRoute(Notification)}</MainLayout>,
  },
  {
    path: Routing.Commission,
    element: <MainLayout>{privateRoute(Commission)}</MainLayout>,
  },
  {
    path: Routing.Payout,
    element: <MainLayout>{privateRoute(Payout)}</MainLayout>,
  },
  {
    path: Routing.NotFound,
    element: (<PublicLayout><NotFound /></PublicLayout>),
  },
  {
    path:Routing.PayoutById,
    element: <MainLayout>{privateRoute(PayoutById)}</MainLayout>,
  },
  {
    path: "*",
    element: (<PublicLayout><NotFound /></PublicLayout>),
  },
  
]);

const AppRouting = () => {
  return <RouterProvider router={routes} />;
};

export default AppRouting;
