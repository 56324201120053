import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISidebarData, sidebarRoutes, } from "../../shared/constants/sidebar-data";
import { IAuthState } from "../state/app-state";
import { ILoginResponseModel } from "../../models/account";
import { IRoutesModel } from "../../models/routes";

const initialState: IAuthState = {
  id: 0,
  userName: "",
  email: "",
  phoneNumber: "",
  role: "",
  token: "",
  permissions: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    adminLogin: (state: IAuthState, action: PayloadAction<ILoginResponseModel>) => {
      state.id = action.payload.id;
      state.userName = action.payload.userName;
      state.email = action.payload.email;
      state.phoneNumber = action.payload.phoneNumber;
      state.role = action.payload.role;
      state.token = action.payload.token;
    },

    adminLogout: () => initialState,

    updatePermission: (state, action: PayloadAction<IRoutesModel[]>) => {
      const permissions = action.payload?.sort((a, b) => (a?.priority > b?.priority ? 1 : -1));
      const newPermission: ISidebarData[] = permissions?.map((route) => {
        const module = sidebarRoutes?.find((x) => x?.module === route?.name);
        if (route?.childRoute?.length > 0) {
          const childRoutes = route?.childRoute?.sort((a, b) => (a?.priority > b?.priority ? 1 : -1));
          return {
            ...module,
            id: route?.id,
            childs: childRoutes
              ?.map((routeChild) => {
                const child = sidebarRoutes?.find((x) => x?.module === routeChild?.name);
                if (child?.name) {
                  return { id: routeChild?.id, ...child };
                }
              })
              .filter((x) => !!x?.name),
          };
        } else {
          return { id: route?.id, ...module };
        }
      });
      state.permissions = newPermission?.filter((x) => !!x?.name) || [];
    },
  },
});

export const { adminLogin, adminLogout, updatePermission } =
  authSlice.actions;

export default authSlice.reducer;
