import React from "react";
import { Navigate } from "react-router-dom";
import store from "../store/store";
import { Routing } from "./routing";
import { useDispatch } from "react-redux";
import { adminLogout } from "../store/slices/authSlice";

const ProtectedRoute = ({ element }) => {
  const dispatch = useDispatch();
  const state = store?.getState();

  const isAuthenticated = state?.UserData && state?.UserData?.token && state?.UserData?.email ? true : false;
  
  if (!isAuthenticated ) {
    dispatch(adminLogout());
    return <Navigate to={Routing.Login} />;
  }

  return <>{element}</>;
};

export default ProtectedRoute;
