// eslint-disable-next-line import/no-anonymous-default-export
export default {
    ClickasaLogo: require('./clikasa_logo.svg').default,
    notFound: require('./notFound.jpg'),
    Security: require('./Security-amico.svg').default,
    IcHamburgDefault: require('./ic-hamburg-default.svg').default,
    IcLanguageLogo: require('./glob.svg').default,
    IcChevron: require('./back.svg').default,
    IcCloseWhite: require('./ic-close-white.svg').default,
    IcDashboard: require('./Dashboard.svg').default,
    IcCustomer: require('./Customer.svg').default,
    IcProvider: require('./Provider.svg').default,
    IcCategory: require('./Category.svg').default,
    IcCompany: require('./Company.svg').default,
    IcModel: require('./Model.svg').default,
    IcServiceMenu: require('./ServiceMenu.svg').default,
    IcBooking: require('./Bookings.svg').default,
    IcNotification: require('./Notification.svg').default,
    IcService: require('./Service.svg').default,
    IcPayout: require('./Payout.svg').default,
    IcCommission: require('./Commission.svg').default,
    IcBillingMethod: require('./BillingMethod.svg').default,
    Map: require('./Map.png'),
    RedMarker: require('./marker1.png'),
    BlueMarker: require('./marker2.png'),
    OrangeDestinationMarker: require('./destination-marker1.png'),
    BlueDestinationMarker: require('./destination-marker2.png'),
    ProfileImg: require('./profile-img.png'),
};
