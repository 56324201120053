import images from "../../assets/images";
import { Routing } from "../../routes/routing";
import { Modules } from "../enums/modules";

export interface ISidebarData {
  id?: number;
  module: string;
  route: string;
  name: string;
  image?: string;
  childs?: ISidebarData[];
}


export const sidebarRoutes: ISidebarData[] = [
  {
    module: Modules.Dashboard,
    route: Routing.Dashboard,
    name: "dashboard",
    image: images.IcDashboard,
  },
  {
    module: Modules.ServiceMenu,
    route: Routing.ServiceMenu,
    name: "service_menu",
    image: images.IcServiceMenu,
  },
  {
    module: Modules.Services,
    route: Routing.Services,
    name: "services",
    image: images.IcService,
  },
  {
    module: Modules.Companies,
    route: Routing.Companies,
    name: "companies",
    image: images.IcCompany,
  },
  {
    module: Modules.Models,
    route: Routing.Models,
    name: "models",
    image: images.IcModel,
  },
  {
    module: Modules.Issues,
    route: Routing.Issues,
    name: "issues",
    image: images.IcCategory,
  },
  {
    module: Modules.BillingMethod,
    route: Routing.BillingMethod,
    name: "billing_method",
    image: images.IcBillingMethod,
  },
  {
    module: Modules.Bookings,
    route: Routing.Bookings,
    name: "job_request",
    image: images.IcBooking,
  },
  {
    module: Modules.Providers,
    route: Routing.Providers,
    name: "technician",
    image: images.IcProvider,
  },
  {
    module: Modules.Customers,
    route: Routing.Customers,
    name: "customer",
    image: images.IcCustomer,
  },
  {
    module: Modules.Notification,
    route: Routing.Notification,
    name: "notification",
    image: images.IcNotification,
  },
  {
    module: Modules.Commission,
    route: Routing.Commission,
    name: "commission",
    image: images.IcCommission,
  },{
    module: Modules.Payout,
    route: Routing.Payout,
    name: "payout",
    image: images.IcPayout,
  }
];

