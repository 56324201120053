import { createTheme } from '@mui/material/styles';

let portalTheme = createTheme({
    palette: {
        common: {
            white: '#ffffff',
            black: '#000000',
        },
        primary: {
            main: '#d4582a',
            dark: '#c45227',
            light: '#de6031',
        },
        secondary: {
            main: '#E9EBED',
            dark: '#D9D9D9',
        },
        text: {
            primary: '#3E3E3E',
            disabled: '#A3A3A3',
        },
        info: {
            main: '#17A2B8',
        },
        error: {
            main: '#D7373F',
            light: '#FF8A8A',
        },
        warning: {
            main: '#FDA53D',
            dark: '#F8824F',
        },
        success: {
            main: '#268E6C',
        },
    },
});

portalTheme = createTheme(portalTheme, {
    // Typography
    typography: {
        fontFamily: 'Roboto, sans-serif',
        h1: {
            fontSize: '1.8rem',
            lineHeight: 1.2,
            fontWeight: 400,
            letterSpacing: 0,
            [portalTheme.breakpoints.up('md')]: { fontSize: '2.13333333333rem' },
        },
        h2: {
            fontSize: '1.3rem',
            lineHeight: 1.2,
            fontWeight: 400,
            letterSpacing: 0,
            [portalTheme.breakpoints.up('md')]: { fontSize: '1.87500rem' },
        },
        h3: {
            fontSize: '1.7rem',
            lineHeight: 1.2,
            fontWeight: 400,
            letterSpacing: 0,
            [portalTheme.breakpoints.up('md')]: { fontSize: '2.5rem' },
        },
        h4: {
            fontSize: '1.6rem',
            lineHeight: 1.2,
            fontWeight: 400,
            letterSpacing: 0,
            [portalTheme.breakpoints.up('md')]: { fontSize: '2rem' },
        },
        h5: { fontSize: '1.33333333333rem', lineHeight: 1.2, fontWeight: 400, letterSpacing: 0 },
        h6: { fontSize: '1.06666666667rem', lineHeight: 1.2, fontWeight: 400, letterSpacing: 0 },
        body1: { fontSize: '1rem', lineHeight: 1.4, fontWeight: 400, letterSpacing: 0 },
        body2: { fontSize: '0.933333333rem', lineHeight: 1.2, fontWeight: 400, letterSpacing: 0 },
        caption: { fontSize: '0.8rem', lineHeight: 1.06666667, fontWeight: 400, letterSpacing: 0 },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: [portalTheme.palette.common.white],
                    boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.1)',
                    borderRadius: 0,
                },
            },
        },

        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: 'auto!important',
                },
            },
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    top: -2,
                    color: [portalTheme.palette.text.disabled],
                    transformOrigin: 'left center',
                    maxWidth: 'calc(100% - 38px)',
                },
                shrink: {
                    maxWidth: '100%',
                },
            },
        },

        MuiBadge: {
            styleOverrides: {
                badge: {
                    top: '10%',
                    right: '10%',
                    fontSize: 11,
                    lineHeight: '13px',
                    fontWeight: 300,
                    zIndex: 0,
                    padding: '0 3px',
                },
                colorError: {
                    backgroundColor: [portalTheme.palette.warning.light],
                },
                colorWarning: {
                    backgroundColor: [portalTheme.palette.warning.dark],
                    color: [portalTheme.palette.common.white],
                },
            },
        },

        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    input: {
                        padding: '11px 14px',
                        height: 24,
                        color: [portalTheme.palette.text.primary],
                    },
                    '&:hover': {
                        fieldset: {
                            borderColor: [portalTheme.palette.primary.main] + '!important',
                        },
                    },
                },
                notchedOutline: {
                    borderColor: [portalTheme.palette.secondary.light],
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {
                adornedEnd: {
                    paddingRight: '16px!important',
                },
            },
        },

        MuiFormControl: {
            styleOverrides: {
                root: {
                    '& p': {
                        color: '#d4582a !important',
                    },
                },
            },
        },

        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginLeft: -8,
                },
            },
        },

        MuiPaper: {
            styleOverrides: {
                root: {
                    background: [portalTheme.palette.common.white],
                    boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.1)',
                    borderRadius: 5,
                    '&.select-wrapper': {
                        maxHeight: 220,
                    },
                },
            },
        },

        MuiInput: {
            styleOverrides: {
                root: {
                    '&:before': {
                        borderBottom: '1px solid #0000006b!important',
                    },
                    '&:hover': {
                        '&:before': {
                            borderBottomColor: [portalTheme.palette.primary.main] + '!important',
                        },
                    },
                },
            },
        },

        MuiTextField: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },

        MuiSelect: {
            styleOverrides: {
                select: {
                    // paddingTop: 13,
                    // paddingBottom: 13,
                    // paddingLeft: 13,
                    padding: 13,
                    // height: 24,
                    textAlign: 'left',
                },
                icon: {
                    width: 5,
                    height: 5,
                    borderBottom: 'solid 1px #a3a3a3',
                    borderRight: 'solid 1px #a3a3a3',
                    transform: 'rotate(45deg)',
                    right: 13,
                    top: 'calc(50% - 4px)',
                    path: {
                        opacity: 0,
                    },
                },
            },
        },

        // Buttons
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontWeight: 400,
                    paddingLeft: 15,
                    paddingRight: 15,
                    boxShadow: 'none!important',
                },
                containedSizeSmall: {
                    fontSize: 12,
                    lineHeight: '15px',
                    paddingTop: 6,
                    paddingBottom: 6,
                },
                containedSizeMedium: {
                    fontSize: 16,
                    lineHeight: '20px',
                    paddingTop: 10,
                    paddingBottom: 10,
                },
                containedSizeLarge: {
                    fontSize: 16,
                    lineHeight: '20px',
                    paddingTop: 15,
                    paddingBottom: 15,
                },
                textSizeSmall: {
                    fontSize: 12,
                    lineHeight: '15px',
                    paddingTop: 6,
                    paddingBottom: 6,
                },
                textSizeMedium: {
                    fontSize: 16,
                    lineHeight: '20px',
                    paddingTop: 10,
                    paddingBottom: 10,
                },
                textSizeLarge: {
                    fontSize: 16,
                    lineHeight: '20px',
                    paddingTop: 15,
                    paddingBottom: 15,
                },
                outlinedSizeSmall: {
                    fontSize: 12,
                    lineHeight: '15px',
                    paddingTop: 5,
                    paddingBottom: 5,
                },
                outlinedSizeMedium: {
                    fontSize: 16,
                    lineHeight: '20px',
                    paddingTop: 9,
                    paddingBottom: 9,
                },
                outlinedSizeLarge: {
                    fontSize: 16,
                    lineHeight: '20px',
                    paddingTop: 14,
                    paddingBottom: 14,
                },
                outlinedPrimary: {
                    borderColor: [portalTheme.palette.primary.main],
                    '&:hover': {
                        backgroundColor: [portalTheme.palette.common.white],
                    },
                },
                outlinedInherit: {
                    borderColor: "#bdbdbd",
                },
                containedPrimary: {
                    borderColor: [portalTheme.palette.primary.main],
                    '&:hover': {
                        backgroundColor: [portalTheme.palette.primary.dark],
                    },
                },

                startIcon: {
                    marginRight: 6,
                    marginLeft: -2,
                },
            },
        },

        MuiIconButton: {
            styleOverrides: {
                sizeMedium: {
                    padding: 4,
                    margin: 0,
                    [portalTheme.breakpoints.up('sm')]: {
                        padding: 8,
                    },
                    '& svg': {
                        width: 25,
                        height: 25,
                        color: '#D4582A',
                    },
                },
            },
        },

        MuiTypography: {
            styleOverrides: {
                root: {
                    whiteSpace: 'normal',
                },
            },
        },

        MuiMenuItem: {
            styleOverrides: {
                root: {
                    minHeight: 'auto',
                },
            },
        },

        MuiTable: {
            styleOverrides: {
                root: {
                    borderCollapse: 'separate',
                    border: '1px solid' + [portalTheme.palette.secondary.light],
                    borderRadius: 5,
                    marginBottom: 4,
                },
            },
        },

        MuiTableCell: {
            
            styleOverrides: {
                root: {
                    borderColor: [portalTheme.palette.secondary.light],
                    '& .MuiCheckbox-root': {
                        marginLeft: -8,
                        marginTop: -8,
                        marginBottom: -8,
                    },
                },
                head: {
                    padding: '17px 10px',
                    fontSize: '1rem',
                    lineHeight: 1.2,
                    fontWeight: 600,
                    color: [portalTheme.palette.text.primary],
                    whiteSpace: 'nowrap',
                    backgroundColor: "#e9ebed",

                    '&:first-of-type': {
                        borderRadius: '10px 0px 0px 0px',
                    },
                    '&:last-child': {
                        borderRadius: '0px 10px 0px 0px',
                    }
                },
                body: {
                    padding: '9px 10px',
                    fontSize: '1rem',
                    lineHeight: 1.4,
                    fontWeight: 400,
                    color: [portalTheme.palette.text.primary],
                    height: '100%',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    '&:first-of-type': {
                        borderLeft:'1px solid #e9ebed'
                    },
                    '&:last-child': {
                        borderRight:'1px solid #e9ebed'
                    }
                },
            },
        },

        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: 'rgba(0, 151, 169, 0.3)',
                    },
                    '&.row-select': {
                        '&:hover': {
                            backgroundColor: 'rgba(0, 151, 169, 0.1)',
                        },
                    },
                    '&:last-child': {
                        '& .MuiTableCell-body': {
                            borderBottom: '1px solid #e9ebed',
                        },
                    },
                },
            },
        },

        MuiTableSortLabel: {
            styleOverrides: {
                icon: {
                    opacity: 1,
                    fill: [portalTheme.palette.secondary.light],
                },
                root: {
                    '&.Mui-active .MuiTableSortLabel-icon': {
                        fill: [portalTheme.palette.text.primary] + '!important',
                    },
                },
            },
        },

        MuiPaginationItem: {
            styleOverrides: {
                sizeSmall: {
                    fontSize: 15,
                    lineHeight: '18px',
                    color: [portalTheme.palette.text.primary],
                    padding: '6px',
                    height: 28,
                    minWidth: 28,
                    margin: '0 4px',
                    '&.Mui-selected': {
                        color: [portalTheme.palette.common.white],
                        backgroundColor: [portalTheme.palette.primary.main],
                        '&:hover': {
                            backgroundColor: [portalTheme.palette.primary.dark],
                        },
                    },
                    '& .MuiPaginationItem-icon': {
                        width: 22,
                        height: 22,
                    },
                },
            },
        },

        MuiBox: {
            styleOverrides: {
                root: {
                    borderRadius: 5,
                    backgroundColor: [portalTheme.palette.common.white],
                    padding: 15,
                    boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.1);',
                    [portalTheme.breakpoints.up('sm')]: {
                        padding: 20,
                    },
                },
            },
        },

        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    '&.Mui-error': {
                        color: "#d4582a",
                        marginLeft:'14px'
                    }
                },
            },
        },

        MuiAutocomplete: {
            styleOverrides: {
                option: {
                    color: [portalTheme.palette.text.primary],
                    padding: '8px 20px!important',
                    fontSize: '0.933333rem',
                    lineHeight: 1.2,
                    '& .MuiTypography-root': {
                        fontSize: '0.93333333rem',
                        lineHeight: 1.2,
                    },
                    '& .highlighted-option': {
                        fontSize: '0.93333333rem',
                        lineHeight: 1.2,
                        fontWeight: 500,
                    },
                },
                root: {
                    '& .MuiFormControl-root': {
                        '& .MuiOutlinedInput-root': {
                            padding: '13px 32px 13px 13px',
                            '& .MuiAutocomplete-input': {
                                padding: 0,
                                marginRight: 40,
                            },
                            '& .MuiAutocomplete-endAdornment': {
                                right: 15,
                                top: 13,
                                '& .MuiAutocomplete-clearIndicator': {
                                    marginRight: 4,
                                    padding: 2,
                                    '& .MuiSvgIcon-root': {
                                        width: 16,
                                        height: 16,
                                        transition: 'all 0.2s ease',
                                    },
                                },
                                '& .MuiAutocomplete-popupIndicator': {
                                    padding: 8,
                                    position: 'relative',
                                    '& .MuiSvgIcon-root': {
                                        position: 'absolute',
                                        top: 4,
                                        width: 5,
                                        height: 5,
                                        borderBottom: 'solid 1.5px #A3A3A3',
                                        borderRight: 'solid 1.5px #A3A3A3',
                                        transition: 'all 0.2s ease',
                                        transform: 'rotate(45deg)',
                                        '& path': {
                                            display: 'none',
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
                popper: {
                    '& .multi-select-menu.MuiAutocomplete-paper': {
                        '& .MuiAutocomplete-listbox': {
                            '& .MuiAutocomplete-option': {
                                padding: '2px 10px 2px 4px!important',
                            },
                        },
                    },
                },
            },
        },

        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: 5,
                    height: 23,
                    fontSize: 13,
                    fontWeight: 500,
                    lineHeight: '15px',
                    boxShadow: 'none!important',
                    '&.rounded': {
                        borderRadius: 24,
                    },
                },
                sizeMedium: {
                    borderRadius: 24,
                    minHeight: 37,
                    fontWeight: 400,
                },
                deleteIcon: {
                    margin: '0 9px 0 -3px',
                },
                labelMedium: {
                    fontSize: 15,
                    lineHeight: 1.2,
                    padding: '9px 9px 9px 12px',
                },
                label: {
                    padding: '4px 6px',
                },
                colorPrimary: {
                    color: [portalTheme.palette.common.white],
                },
                colorInfo: {
                    color: [portalTheme.palette.common.white],
                },
                colorSuccess: {
                    color: [portalTheme.palette.common.white],
                },
                colorWarning: {
                    color: [portalTheme.palette.common.white],
                },
                filled: {
                    '&.MuiChip-filledInfo': {
                        // backgroundColor: [portalTheme.palette.info.main] + '!important',
                        backgroundColor: '#edeff1    !important',
                        color: '#637381 !important',
                    },
                    '&.MuiChip-filledWarning': {
                        backgroundColor: [portalTheme.palette.warning.main] + '!important',
                        color: [portalTheme.palette.common.black] + '!important',
                    },
                    '&.MuiChip-filledSuccess': {
                        // backgroundColor: [portalTheme.palette.success.main] + '!important',
                        backgroundColor: '#dbf6e5 !important',
                        color: '#209562 !important',
                    },
                    '&.MuiChip-filledError': {
                        // backgroundColor: [portalTheme.palette.error.main] + '!important',
                        backgroundColor: '#f9dfdd !important',
                        color: '#c34a41 !important',
                    },
                    '&.MuiChip-filledSecondary': {
                        // backgroundColor: [portalTheme.palette.secondary.dark] + '!important',
                        backgroundColor: '#fdefdd !important',
                        color: '#b95013 !important',
                    },
                    '&.MuiChip-filledPrimary': {
                        backgroundColor: [portalTheme.palette.primary.main] + '!important',
                        color: [portalTheme.palette.common.white] + '!important',
                        '& .MuiChip-deleteIconOutlinedColorPrimary': {
                            color: [portalTheme.palette.common.white] + '!important',
                        },
                    },
                },
                outlined: {
                    '&.MuiChip-outlinedPrimary': {
                        borderColor: '#2B66AA',
                        color: '#2B66AA',
                        '&:hover': {
                            backgroundColor: [portalTheme.palette.primary.main] + '!important',
                            color: [portalTheme.palette.common.white] + '!important',
                            '& .MuiChip-deleteIconOutlinedColorPrimary': {
                                color: [portalTheme.palette.common.white] + '!important',
                            },
                        },
                    },
                },
            },
        },

        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: 'auto',
                },
            },
        },

        MuiListSubheader: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    fontSize: '1rem',
                    lineHeight: '32px',
                },
            },
        },

        MuiSwitch: {
            styleOverrides: {
                root: {
                    marginLeft: -9,
                },
                track: {
                    opacity: 0.2,
                    height: 12,
                },
                thumb: {
                    width: 18,
                    height: 18,
                },
                switchBase: {
                    '&.Mui-disabled': {
                        '& .MuiSwitch-thumb': {
                            backgroundColor: [portalTheme.palette.text.disabled],
                        },
                        '& + .MuiSwitch-track': {
                            backgroundColor: [portalTheme.palette.secondary.dark],
                        },
                    },
                },
            },
        },

        MuiMenu: {
            styleOverrides: {
                list: {
                    '&.multi-select-menu': {
                        '& .MuiMenuItem-root': {
                            padding: '2px 8px',
                        },
                    },
                },
            },
        },

        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: [portalTheme.palette.common.white],
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.10)',
                    borderRadius: 5,
                    minHeight: '100%',
                    '& .MuiCardHeader-root': {
                        padding: '20px 20px 14px',
                        '& .MuiCardHeader-title': {
                            display: 'flex',
                            flexWrap: 'wrap',
                            lineHeight: 1.4,
                            fontSize: 18,
                        },
                        '& .title': {
                            fontSize: 18,
                            lineHeight: 1.2,
                        },
                        '& .MuiCardHeader-action a': {
                            display: 'block',
                            marginTop: 4,
                            marginBottom: 4,
                            marginRight: 8,
                        },
                    },
                    '& .MuiCardContent-root': {
                        padding: '0 20px 20px',
                        '&:last-child': {
                            paddingBottom: 20,
                        },
                    },
                },
            },
        },

        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: [portalTheme.palette.common.white],
                    borderRadius: 10,
                    margin: 15,
                    [portalTheme.breakpoints.up('md')]: {
                        margin: 40,
                    },
                },
                paperWidthSm: {
                    maxWidth: 420,
                    width: 420,
                },
                paperWidthMd: {
                    maxWidth: 600,
                    width: 600,
                },
                paperWidthLg: {
                    maxWidth: 1056,
                    width: 1056,
                },
                paperWidthXl: {
                    maxWidth: 1400,
                    width: 1400,
                },
            },
        },

        // Dialog
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '20px 20px 0!important',
                    minHeight: 40,
                    '&.no-action': {
                        padding: '25px!important',
                        [portalTheme.breakpoints.up('sm')]: {
                            padding: '35px!important',
                        },
                    },
                    '&.form-dialog-content': {
                        padding: '20px 20px 16px!important',
                        [portalTheme.breakpoints.up('sm')]: {
                            padding: '20px!important',
                        },
                    },
                },
            },
        },

        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '16px 20px!important',
                    flexWrap: 'wrap',
                    '&>:first-of-type': {
                        margin: '4px 0',
                    },
                    '&>:not(:first-of-type)': {
                        margin: '4px 0',
                        marginLeft: 8,
                        [portalTheme.breakpoints.up('sm')]: {
                            marginLeft: 20,
                        },
                    },
                    '&.manage-patient': {
                        padding: '20px 0!important',
                    },
                    [portalTheme.breakpoints.up('sm')]: {
                        padding: '20px!important',
                        flexWrap: 'normal',
                    },
                    '&.form-dialog-action': {
                        padding: '16px 0 0!important',
                        flexWrap: 'wrap',
                        [portalTheme.breakpoints.up('sm')]: {
                            padding: '20px 0 0!important',
                            flexWrap: 'normal',
                        },
                    },
                },
            },
        },

        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '12px 16px',
                    backgroundColor: [portalTheme.palette.primary.main],
                    color: [portalTheme.palette.common.white],
                    '& .MuiTypography-root': {
                        fontSize: '1.2rem',
                        lineHeight: 1.3333333,
                    },
                    '& .close-action': {
                        minWidth: 24,
                        margin: '-4px -4px -4px 0',
                    },
                },
            },
        },

        MuiCalendarPicker: {
            styleOverrides: {
                root: {
                    '& .MuiPickersDay-root': {
                        width: 32,
                        height: 32,
                        '& .PrivatePickersSlideTransition-root': {
                            minHeight: 196,
                        },
                    },
                },
            },
        },

        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: 'rgba(78,78,78,1)!important',
                    marginTop: '8px!important',
                    marginBottom: '8px!important',
                    fontWeight: '400!important',
                },
                arrow: {
                    '&:before': {
                        backgroundColor: 'rgba(78,78,78,1)!important',
                    },
                },
            },
        },

        // Tabs
        MuiTab: {
            styleOverrides: {
                root: {
                    padding: '10px 0',
                    textTransform: 'none',
                    letterSpacing: 0,
                    fontSize: 15,
                    lineHeight: '19px',
                    minHeight: 'auto',
                    [portalTheme.breakpoints.up('md')]: {
                        fontSize: 18,
                        lineHeight: '24px',
                        minHeight: 48,
                    },
                },
            },
        },

        // Stepper
        MuiStepper: {
            styleOverrides: {
                root: {
                    marginTop: 30,
                    marginBottom: 40,
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    [portalTheme.breakpoints.up('sm')]: {
                        width: 30,
                        height: 30,
                    },
                },
            },
        },
        MuiStepConnector: {
            styleOverrides: {
                root: {
                    [portalTheme.breakpoints.up('sm')]: {
                        top: 13,
                        left: ' calc(-50% + 20px)',
                        right: 'calc(50% + 20px)',
                    },
                },
            },
        },

        MuiStepLabel: {
            styleOverrides: {
                root: {
                    '.MuiStepLabel-labelContainer': {
                        [portalTheme.breakpoints.down('md')]: {
                            display: 'none',
                        },
                    },
                },
            },
        },

        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    borderRadius: 5,
                },
            },
        },

        MuiToggleButton: {
            styleOverrides: {
                sizeLarge: {
                    padding: 8,
                    [portalTheme.breakpoints.up('sm')]: {
                        padding: 13,
                    },
                },
                primary: {},
                root: {
                    border: '1px solid',
                    borderColor: [portalTheme.palette.primary.main] + '!important',
                    '&:hover': {
                        backgroundColor: [portalTheme.palette.primary.light] + '!important',
                    },
                    '&.Mui-selected': {
                        backgroundColor: [portalTheme.palette.primary.main] + '!important',
                    },
                },
            },
        },

        MuiRadio: {
            styleOverrides: {
                root: {
                    padding: 7,
                    '& + .MuiFormControlLabel-label': {
                        lineHeight: 1.5,
                    },
                },
            },
        },

        MuiImageListItemBar: {
            styleOverrides: {
                titleWrap: {
                    width: '60%',
                },
                actionIcon: {
                    width: '40%',
                },
            },
        },

        MuiAvatar: {
            styleOverrides: {
                root: {
                    width: 25,
                    height: '100%',
                    // '& img': {
                    //     objectFit: 'contain',
                    // },
                },
            },
        },

        MuiAccordion: {
            styleOverrides: {
                root: {
                    '&.bordered-accordion': {
                        borderRadius: '5px!important',
                        border: '1px solid #D1D1D1',
                        backgroundColor: [portalTheme.palette.primary.light],
                        boxShadow: 'none',
                        transition: 'all 0.2s ease',
                        '&:before': {
                            display: 'none',
                        },
                        '& .MuiAccordionSummary-root': {
                            padding: '0 10px',
                            '&.Mui-expanded': { minHeight: 'auto' },
                        },
                        '& .MuiAccordionSummary-content': {
                            '&.Mui-expanded': { margin: '14px 0' },
                        },
                        '&.Mui-expanded': {
                            margin: '10px 0 0',
                            backgroundColor: [portalTheme.palette.common.white],
                            '&:first-of-type': {
                                margin: 0,
                            },
                        },
                        '& .MuiAccordionDetails-root': {
                            padding: 10,
                            borderTop: '1px solid #D1D1D1',
                        },
                    },
                },
            },
        },
    },
});

export default portalTheme;
