import { ThemeProvider } from "@mui/material/styles";
import { Suspense } from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRouting from "./routes/AppRouting";
import store from "./store/store";
import portalTheme from "./theme/App.theme";
// import Loader from "./layout/Loader";
import './assets/styles/Global/global.css';
import './assets/styles/Global/spacer.css';
import './assets/styles/Global/style.css';
import Loader from "./layout/Loader";


function App() {
  return (
      <Provider store={store}>
        <Suspense fallback={<Loader />}>
        <ToastContainer limit={3} autoClose={3000} className="toaster" hideProgressBar={true} />
          <ThemeProvider theme={portalTheme}>
            <AppRouting />
          </ThemeProvider>
        </Suspense>
      </Provider>
  );
}

export default App;
